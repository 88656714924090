import {Component, OnInit} from '@angular/core';
import {BankAccountModel} from '@app/models';

// Services
import {ProfileService} from '@app/services';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {filter, tap} from "rxjs/operators";
import {BankAccountEvent} from "@app/types/profile";
import {map} from "rxjs";

@UntilDestroy()
@Component({
  selector: 'bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['bank-account.component.scss'],
})
export class BankAccountComponent implements OnInit {

  accounts: Array<BankAccountModel | {}> = null;

  isAllowedToAddAccount: boolean = false;

  country: string = this.profileService.syncGetUser().country;

  showAddButton: boolean = false;

  constructor(private profileService: ProfileService,
  ) {
  }

  ngOnInit(): void {
    this.profileService.getBankAccount().subscribe((responce) => {
      this.isAllowedToAddAccount = responce.mangopay;
    });

    this.profileService.getStatusBankAccount()
      .pipe(untilDestroyed(this),
        filter((bankEvent: BankAccountEvent) => bankEvent !== null),
        map((bankEvent: BankAccountEvent) => bankEvent === BankAccountEvent.CANCEL),
        tap((isCancel: boolean) => {
          this.showAddButton = isCancel;
          if (isCancel && Array.isArray(this.accounts)) {
            this.accounts = this.accounts.filter(account => Object.keys(account).length > 0);
          }
          this.showAddBankAccount(this.accounts);
        }))
      .subscribe();

    this.profileService.bankAccounts$.pipe(untilDestroyed(this), filter(acc => acc !== null)).subscribe(
      (accounts: BankAccountModel[]) => {
        if (!accounts?.length) {
          this.accounts = [{}];
        } else {
          this.accounts = accounts;
        }
        this.showAddBankAccount(accounts);
      }
    )

  }

  showAddBankAccount(accounts) {
    this.showAddButton = !accounts?.length;
  }

  addBlankAccount(): void {
    if (this.accounts.length) {
      return;
    }

    this.accounts = [...this.accounts, ...[{}]];
    this.showAddButton = false;
  }
}
