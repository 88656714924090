import * as moment from 'moment';
import { DEFAULT_SONG_IMG } from '../common/constants_IMG';
import { decimalType } from '../types/common';
import { MongooseModel } from './mongoose.model';
import { environment } from '../../environments/environment';
import { PriceForShare } from '@app/types';
export class CardModel extends MongooseModel {
  constructor() {
    super();
    this.imgUrl = environment.imgUrl;
  }

  isCollectible: boolean;
  isProject: boolean;
  imgUrl: any;
  artist: string;
  song: string;
  position: number;
  auctionMarket: string;
  finished: boolean;
  backgroundImage: string;
  projectNotStarted: boolean;
  daysLeft: number;
  projectEndDate: moment.Moment;
  projectStartDate: moment.Moment;
  endDate: Date;
  startDate: Date;
  cardColor: string;
  cardDescription: string;
  edition: number;
  editionNumber?: number;
  typeEditions?: boolean;
  goodiesIcon: boolean;
  limitedIcon: boolean;
  keepItAll: boolean;
  legacyHit: any;
  artistCategory: string;
  cardArtistTitle: string;
  isFeatured: boolean;
  releaseCreated: boolean;
  totalInvestorsLeft: number;
  totalInvestorsShares: number;
  pricePerShare: number;
  priceForShare: PriceForShare;
  decimalType: decimalType;
  progressBar: number;
  songs: any;
  isBundle: boolean;
  publish_year: string;
  releaseCreatedDate: string | null;
  enableLogo?: boolean;
  isFailed: boolean;
  projectStartTime: string;
  isFunded: boolean;
  isSold: boolean;
  isAlbum?: boolean;
  saleAmount: number;
  salePricePerShare: number | null;
  imgNFT?: string;
  projectTitle?: string;
  royaltiesFromMaster: boolean;
  royaltiesFromPublishingRights: boolean;
  video?: string;

  setFromApiModel(
    apiModel: any,
    currency: string,
    showLogo?: boolean,
    isCollectible?: boolean,
  ): this {
    Object.assign(this, apiModel);
    this.isCollectible = isCollectible;
    this.isProject =
      !isCollectible || isCollectible === undefined ? true : false;

    this.pricePerShare = apiModel.priceForShare ? apiModel.priceForShare[currency] : 0;

    this.artistCategory = apiModel.artistCategory || 'grs';

    this.salePricePerShare = apiModel.saleAmount
      ? this.pricePerShare - (apiModel.saleAmount * this.pricePerShare) / 100
      : null;
    this.isSold = !!apiModel.soldOut || apiModel.isFunded;
    this.enableLogo = showLogo;
    this.auctionMarket = apiModel.editionId;
    const artistShare = 100 - this.totalInvestorsShares;
    this.progressBar = apiModel.totalInvestedPercents
      ? ((apiModel.totalInvestedPercents + artistShare) / 100) * 100
      : artistShare;

    if (apiModel.projectDurationTime?.endDate) {
      this.projectEndDate = moment(apiModel.projectDurationTime.endDate);
    } else {
      this.projectEndDate = moment(apiModel.endDate);
    }
    if (apiModel.projectDurationTime?.startDate) {
      this.projectStartDate = moment(apiModel.projectDurationTime.startDate);
    } else {
      this.projectStartDate = moment(apiModel.startDate);
    }
    this.video = null;
    this.backgroundImage = apiModel.backgroundImage
      ? `${this.imgUrl}/${apiModel.backgroundImage}`
      : DEFAULT_SONG_IMG;
    this.imgNFT = apiModel.imgNFT ? `${this.imgUrl}/${apiModel.imgNFT}` : null;
    this.isFailed = !this.isFunded && this.daysLeft < 0 && !this.isSold;
    if (isCollectible) {
      this.publish_year = moment(apiModel.startDate).format('YYYY');
    } else {
      this.publish_year = apiModel.song?.publishingYear || 2025;
    }
    return this;
  }
}
