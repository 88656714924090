import {Component, OnInit} from '@angular/core';
import {AppToastrService, ProfileService} from 'app/services';
import {UserModel, RequestModel} from 'app/models';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import * as moment from 'moment';
import {
  IPagination,
  LEGAL_TYPEs,
  MANGOPAY_LIST,
  MONTH,
  SEP_COUNTRIES,
  STATE_NAMES,
  YEARS
} from '../../common';
import {CustomFieldErrorMatcher} from '../../common/errorMatcher';
import {Router} from '@angular/router';
import {ProjectsService} from '../../services/projects.service';
import {StorageService} from '../../services/storage.service';
import {ReleaseService} from "@services/release.service";
import {IRevenueItem} from "@app/pages/revenue/types/revenue-response.interface";

@Component({
  selector: 'app-revenue',
  templateUrl: './revenue.html',
})

export class RevenueComponent implements OnInit {
  projectList: any[] = [];
  user: UserModel;
  monthFromControl: FormControl;
  yearFromControl: FormControl;
  monthToControl: FormControl;
  yearToControl: FormControl;
  form: FormGroup;
  showWithdrawPopup: boolean;
  isSEP: boolean;
  footer: any;
  listFilters: any = {};
  showAlert: boolean;
  showVoucherPopup: boolean;
  date: Date;
  defYear: number;
  uploadedFile: boolean;
  defMonth: number;
  showVerifyPopup: boolean;
  revenues: Array<IRevenueItem>;
  defaultFilters = {
    paging: {
      page: 1,
      offset: 0,
      limit: 10,
    },
    from: null,
    to: null
  };
  openSetting: boolean;
  isLoaded: boolean;
  showUploadPopup: boolean;
  voucherAmount: number;
  hasAdvances = false;
  showPdf = false;
  legalTypeBusiness: LEGAL_TYPEs = 'business';
  fileUpload: boolean;
  agreementInProgress: boolean;
  submitPressed: boolean;
  uboStatusValid: boolean;
  isFiltersFromValid: boolean;
  isFiltersToValid: boolean;
  total: number;
  reinvestLink: string;
  month = MONTH;
  filterApply: boolean;
  years = YEARS;
  storageUser: any;
  withdrawAmount: any;
  ios: any;
  // TODO set here pdf to show in pop up;
  pdfToShow = {
    url: '',
    type: ''
  };

  constructor(
    private projectService: ProjectsService,
    private releaseService: ReleaseService,
    private toastr: AppToastrService,
    private storage: StorageService,
    private fb: FormBuilder,
    private router: Router,
    private profileService: ProfileService,
  ) {
    this.listFilters = JSON.parse(JSON.stringify(this.defaultFilters));
    this.storageUser = this.storage.user;
    this.uboStatusValid = true;
    const userAgent = window.navigator.userAgent.toLowerCase();
    this.ios = /iphone|ipod|ipad/.test(userAgent);
  }

  ngOnInit() {
    this.getUser();
    this.fetchInvestment();
    this.prepareForm();
    this.getGlobalSettings();

  }

  checkAdvances() {
    if (this.user.balance && this.user.balance.open) {
      this.hasAdvances = true;
    }
    if (this.user.balance && this.user.balance.open) {
      this.user.recoupable += this.profileService.calcualteRecoupable(this.user.balance);
    }
  }

  getUser() {
    const request = new RequestModel({kyc: true, investment: true});
    this.profileService.getUser(request).subscribe((resp) => {
      this.user = resp;
      this.withdrawAmount = 0;
      if (this.user.revenues && this.user.revenues.payouts) {
        this.user.revenues.payouts.forEach(item => {
          if (!item.failed && !item.refunded) {
            this.withdrawAmount += item.amount;
          }
        });
      }
      //  this.user.revenues.total = this.user.recoupable ? (Number(this.user.recoupable) + Number(this.user.revenues.total)).toFixed(2) : this.user.revenues.total;
      this.checkAdvances();

      // if (this.user.recoupable) {
      //   let summary = this.user.recoupable + this.user.reinvestTotal + this.withdrawAmount;
      //   this.user.revenues.total = (Number(summary) + Number(this.user.revenues.left)).toFixed(2);
      // }

      if (!this.user.isPrivate) {
        this.uboStatusValid = this.user.statusUBO && this.user.statusUBO.id === 2;
      }
      this.isSEP = MANGOPAY_LIST.indexOf(this.user.country) >= 0 || SEP_COUNTRIES.indexOf(this.user.country) >= 0;
    });
  }

  getGlobalSettings() {
    this.profileService.getSettings().subscribe((resp) => {
      this.reinvestLink = resp.reinvestURL;
    }, err => {
      this.toastr.showToastFromError(err);
    });
  }

  applyVoucher() {
    if (!this.storageUser.verified) {
      this.showVerifyPopup = true;
    } else {
      this.showVoucherPopup = true;
    }
  }

  downloadRevenuesCSV() {
    if (this.revenues && this.revenues.length) {
      this.fileUpload = true;
      const data: any = {
        dateFrom: this.listFilters.from ? this.listFilters.from : null,
        dateTo: this.listFilters.to ? this.listFilters.to : null,
        filter: this.filterApply
      };
      const request = new RequestModel(data);
      this.projectService.downloadCSV(request, 'revenues').subscribe(res => {
        this.fileUpload = false;
        const a = document.createElement('a');
        a.href = 'data:attachment/csv,' + encodeURIComponent(res.data);
        a.target = '_blank';
        a.download = `Revenues(${data.dateFrom} - ${data.dateTo})` + '.csv';
        a.click();
      }, err => {
        this.toastr.showToastFromError(err);
      });
    }
  }

  showSubRow(item: any, hasStatements?: number) {
    if (hasStatements) {
      item.isOpen = !item.isOpen;
    }
  }

  checkProjects() {
    this.router.navigate([`/${STATE_NAMES.opportunity}`]);
  }

  goToReinvest() {
    window.location.href = this.reinvestLink;
  }

  fetchInvestment() {
    const data: any = {};
    const request = new RequestModel(data);
    this.releaseService.getReleases(request).subscribe(resp => {
      if (resp) {
        let date = Date.now();
        resp.investments.forEach(element => {
          if (element.pledges && element.pledges.own && !element.isRefund) {
            const pledgeDate = Date.parse(element.pledges.own.createdAt);
            if (date > pledgeDate) {
              date = pledgeDate;
            }
          }
        });
        this.date = new Date(date);
        this.defYear = new Date(Date.now()).getFullYear() - this.date.getFullYear();
        this.defMonth = new Date(Date.now()).getMonth() - this.date.getMonth();
        this.yearFromControl = this.fb.control(+moment().subtract(this.defYear, 'year').format('YYYY'));
        this.monthFromControl = this.fb.control(+moment().subtract(this.defMonth, 'months').format('M'));
        this.form = this.fb.group({
          yearFrom: this.yearFromControl,
          monthFrom: this.monthFromControl,
          yearTo: this.yearToControl,
          monthTo: this.monthToControl,
        });
        this.onDateChange(true);
      }
    }, err => {
      this.isLoaded = true;
      this.submitPressed = false;
      this.toastr.showToastFromError(err);
    });
  }


  onDateChange(first?: boolean, filterApply?: boolean): void {
    this.submitPressed = !first;
    if (first || (this.isFiltersFromValid && this.isFiltersToValid)) {
      this.isLoaded = false;
      const val = this.form.value;
      if (val.monthFrom) {
        this.listFilters.from = moment(`${val.monthFrom}/${val.yearFrom}`, 'M/YYYY').startOf('month').format('DD-MM-YYYY');
      }
      if (val.monthTo) {
        this.listFilters.to = moment(`${val.monthTo}/${val.yearTo}`, 'M/YYYY').endOf('month').format('DD-MM-YYYY');
      }
      if (filterApply) {
        this.filterApply = filterApply;
      }
      this.getRevenues();
    }
  }

  prepareForm(): void {
    this.yearFromControl = this.fb.control(+moment().subtract(this.defYear, 'year').format('YYYY'));
    this.monthFromControl = this.fb.control(+moment().subtract(this.defMonth, 'months').format('M'));
    this.yearToControl = this.fb.control(+moment().format('YYYY'));
    this.monthToControl = this.fb.control(+moment().format('M'));
    this.form = this.fb.group({
      yearFrom: this.yearFromControl,
      monthFrom: this.monthFromControl,
      yearTo: this.yearToControl,
      monthTo: this.monthToControl,
    });
    this.onDateChange();
  }

  withdrawEvent(disable: boolean) {
    if (!disable) {
      this.router.navigate([`/${STATE_NAMES.withdraw}`], {state: {isRevenue: true}});
    }
  }

  resetFilters() {
    this.yearFromControl.setValue(+moment().subtract(this.defYear, 'year').format('YYYY'));
    this.monthFromControl.setValue(+moment().subtract(this.defMonth, 'months').format('M'));
    this.yearToControl.setValue(+moment().format('YYYY'));
    this.monthToControl.setValue(+moment().format('M'));
    this.filterApply = false;
    this.onDateChange(false);
  }

  openSection() {
    this.openSetting = !this.openSetting;
  }

  getValidation(){
   return this.user && ((this.user.revenues.left < 2.5 || this.user.isPendingStatus || this.hasAdvances
    || [2,3].indexOf(this.user.mangoVerificationStatus?.id) >=0 || (!this.uboStatusValid && !this.user.isPrivate)));
  }

  loadReport(data) {
    const {url, preview} = data;
    this.pdfToShow.url = url;
    this.pdfToShow.type = 'xls';
    if (preview && window.innerWidth > 767) {
      this.showPdf = true;
    } else {
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = 'Report.xlsx';
      a.click();
    }
  }

  showPdfPopUp(event): void {
    const {data} = event;
    const file = new Blob([data], {type: 'application/pdf'});
    this.pdfToShow.url = URL.createObjectURL(file);
    this.pdfToShow.type = 'pdf';
    this.showPdf = true;
  }

  getRevenues() {
    const data: any = {
      limit: this.listFilters.paging.limit,
      page: this.listFilters.paging.page,
      dateFrom: this.listFilters.from ? this.listFilters.from : null,
      dateTo: this.listFilters.to ? this.listFilters.to : null,
      filter: this.filterApply
    };
    this.isLoaded = false;
    const request = new RequestModel(data);
    this.releaseService.getMyRevenues(request).subscribe(resp => {
      if (resp) {
        this.total = resp.total;
        this.revenues = resp.revenues;
        this.isLoaded = true;
      }
    }, err => {
      console.log(err)
      this.isLoaded = true;
      this.toastr.showToastFromError(err);
    });
  }

  onPageChange(pageNumber: number) {
    this.listFilters.paging.page = pageNumber + 1;
    this.listFilters.paging.offset = pageNumber * this.listFilters.paging.limit;
    this.getRevenues();
  }

  onPageRangeChange(pageRange: IPagination) {
    this.listFilters.paging = pageRange;
    this.getRevenues();
  }

  errorFromMatcher(control) {
    let error;
    this.isFiltersFromValid = true;
    const val = this.form.value;
    if ((val.monthFrom || val.yearFrom) && !moment(`${val.monthFrom}/${val.yearFrom}`, 'M/YYYY').isValid()) {
      error = true;
      this.isFiltersFromValid = false;
    }
    return new CustomFieldErrorMatcher(control, this.submitPressed, error);
  }

  onNotify(e) {
    this.showWithdrawPopup = false;
    this.showVoucherPopup = false;
    this.showVerifyPopup = false;
    this.showAlert = false;
    this.showPdf = false;
    if (e.type === 'error' && e.error === 'UserNotFilledProfile') {
      this.showAlert = true;
    }
    if (e.type === 'deposit') {
      this.user.revenues.left = (Number(e.data) + Number(this.user.revenues.left)).toFixed(2);
    }
    if (e.type === 'uploaded') {
      this.uploadedFile = true;
    }

    if (e.type === 'submitVoucher') {
      this.getUser();
    }
  }

  alertPopupHandle(isClose?: boolean): void {
    this.showAlert = false;
    if (!isClose) {
      this.router.navigate([`/${STATE_NAMES.profile}`]);
    }
  }

  errorToMatcher(control) {
    let error;
    this.isFiltersToValid = true;
    const val = this.form.value;
    const from = moment(`${val.monthFrom}/${val.yearFrom}`, 'M/YYYY');
    const to = moment(`${val.monthTo}/${val.yearTo}`, 'M/YYYY');
    if ((val.monthTo || val.yearTo) && !to.isValid()) {
      error = true;
      this.isFiltersToValid = false;
    }
    if (from.isValid() && Number(from.format('X')) > Number(to.format('X'))) {
      error = true;
      this.isFiltersToValid = false;
    }
    return new CustomFieldErrorMatcher(control, this.submitPressed, error);
  }
}
